<!--报关单打印模板-->
<template>
    <div
        class="declare-box"
        :style="{display: pageLength?'block':'inline-block'}"
    >
        <div class="declare-wrap" v-for="(data, index) in datas" :key="index">
            <div class="declare-page" style="position:relative;">
                <div class="dtop">
                    <div class="dlogo"></div>
                    <div class="dtitle">COMMERCIAL INVOICE</div>
                </div>
                <div class="dbarcode-wrap">
                    <div class="dbarcode1-img">
                        <img :src="'data:image/png;base64,'+data.codeAsBase64" />
                        <span>{{data.code}}</span>
                    </div>
                    <ul class="list-title">
                        <li class="title-cell0">MARK & NO.</li>
                        <li class="title-cell1">DESCRIPTION<br />GOODS(品名)</li>
                        <li class="title-cell2">(單價)<br />U.PRICE</li>
                        <li class="title-cell3">Quantity<br/>(数量)</li>
                        <li class="title-cell4">(總計)<br />AMOUNT</li>
                    </ul>
                </div>
                <div class="ddetails">
                    <ul v-for="(details, idx) in data.details" :key="idx">
                        <li v-for="(d, jdx) in details" :class="'dcell-'+jdx" :key="jdx">{{d}}</li>
                    </ul>
                </div>
                <div class="dinfo-wrap">
                    <h3>发件人： {{data.sender}}</h3>
                    <h3>收件人： {{data.receiver}}</h3>
                    <h5>收件人地址： {{data.receiverAddr}}</h5>
                    <h4>收件电话：{{data.receiverTel}}</h4>
                    <div class="damt">
                        <div>目的地：台湾</div>
                        <div style="padding-right:5px;">SAY TOTAL : <!--{{data.amount}}-->[总价合]</div>
                    </div>
                    <div class="dcompany">
                        <div>{{data.expCompany}}</div>
                        <div>{{data.expCompany}}</div>
                    </div>
                    <div class="dbarcode2-img">
                        <img :src="'data:image/png;base64,'+data.codeAsBase64" />
                        <span>{{data.code}}</span>
                    </div>
                    <div class="dtarget">桃园分单号————{{data.expCompany}}</div>
                    <div class="dbarcode3-img">
                        <img :src="'data:image/png;base64,'+data.codeAsBase64" />
                        <span>{{data.code}}</span>
                    </div>
                </div>
            </div>
            <div class="next-page a5" v-if="datas.length>1 && index<datas.length"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "StockDeclarePrintTpl",
    props: {
        datas: Array,
    },
    computed: {
        pageLength: { 
            get() {
                if(this.datas.length > 1){
                  return true;
                }else{
                  return false;
                }
            }
        },
    },
    methods:{
         
    }
};
</script>

<style lang="scss" scoped>
.next-page {
  page-break-before: always;
}
.declare-box{width:100%;   box-sizing: border-box;}
.declare-wrap{width: 400px; margin:0px auto;}
.dtop{border: 1px solid black; display:flex; }
.dlogo{flex: 0.8;}
.dtitle{flex: 1.2; padding: 8px 0px 8px 8px; 
  font-size: 18px; border-left: 1px solid black;}

.dbarcode-wrap{border: 1px solid black; border-top: 0px none; 
  padding: 10px 0px 0px 0px;
}
.dbarcode1-img{display:flex; flex-direction: column; align-items: center;}
.dbarcode1-img span{font-size:18px;}

.list-title{list-style: none; padding: 5px 5px 0px 5px; margin: 0px;
  display:flex; font-size:13px; 
}
.list-title li{text-align:center;}

.ddetails{border: 1px solid black; margin-top:3px;}

.ddetails ul{list-style:none; margin:0px; padding: 5px 0px 5px 0px; ; 
  display:flex;  
  border-bottom: 1px solid black;
  align-items:center;
}

.ddetails ul:last-child{border-bottom:none;}

.title-cell0, .dcell-0{width: 22%; text-align:center;}
.title-cell1, .dcell-1{width: 25%; text-align:center;  overflow:hidden;}
.dcell-1{font-size:12px;}
.title-cell2, .dcell-2{width: 18%; text-align:center;}
.title-cell3, .dcell-3{width: 18%; text-align:center;}

.dinfo-wrap{border: 1px solid black; margin-top: 3px; padding: 5px 0px 0px 10px;}
.dinfo-wrap h3{margin:0px; font-weight: normal;}
.dinfo-wrap h5{font-weight: normal; margin:0px;}
.dinfo-wrap h4{font-weight:normal; margin:0px;}

.damt{display:flex; justify-content: space-between;}

.dcompany{ display:flex;}
.dcompany > div:first-child{width:50%}
.dcompany > div:last-child{padding-left:20px;}

.dbarcode2-img{display:flex; flex-direction: column; align-items: center;}
.dbarcode3-img{display:flex; flex-direction: column; align-items: center;}

</style>
