<!--打印中通快递单-->
<template>
    <div
        class="print-box"
        v-if="ctrls && ctrls.length>0"
        :style="blockStyle"
    >   
        <div class="print-wrap" v-for="(data, index) in datas" :key="index">
            <template v-if="data.pageDto || data.tagDto || data.thumbs || data.ticketDto || (data.shelfCodeDto && ctrls.indexOf(5)>-1) || ctrls.indexOf(6)>-1 || ctrls.indexOf(7)>-1">
                <!--1-打印顺丰快递单-->
                <div v-if="ctrls.indexOf(1)>-1" class="vw400 invoice-box">
                    <div v-if="user.deptPath.indexOf('/1/6262/2') < 0">
                        <div class="invoice-header1" style="margin:10px 0;">
                            <div class="company">
                                <img style="width: 50px; height: 50px" src="../../assets/img/ticket1.png" />
                                <span v-if="user.deptPath.indexOf('/1/9053/9054')>-1" style="font-size: 30px;font-weight:bold;position: relative;top: -10px;left: 10px;">{{"春丰速运"}}</span>
                                <span v-else style="font-size: 30px;font-weight:bold;position: relative;top: -10px;left: 10px;">{{"顺运速递"}}</span>
                            </div>
                            <div style="font-size: 20px;font-weight:bold;border:1px solid #000;padding:5px 15px;">{{"快  递"}}<div>{{"包  裹"}}</div></div>
                        </div>
                        <div class="dotted-line2"></div>
                        <div class="invoice-header" style="margin-top:12px;">
                            <div class="barcode-warp">
                                <img :src="'data:image/png;base64,'+data.ticketDto.barcodeAsBase64" />
                                <div style="margin-top:5px;">{{data.ticketDto.code?data.ticketDto.code:''}}</div>
                            </div>
                        </div>
                        <div class="dotted-line2"></div>
                        <div class="invoice-header1" style="margin:10px 0;">
                            <div class="company">
                                <img style="width: 30px; height: 30px;margin:5px 0" src="../../assets/img/ticket2.png" />
                                <span style="font-size: 20px;font-weight:bold;position: relative;top: -10px;left: 10px;">{{"深圳中转包"}}</span>
                                <span style="font-size: 30px;font-weight:bold;position: relative;top: -10px;left: 100px;">{{data.ticketDto.code.substring(data.ticketDto.code.length-4)}}</span>
                            </div>
                        </div>
                        <div class="dotted-line2"></div>
                        <div class="invoice-header2" style="margin:10px 0;">
                            <div class="company">
                                <img style="width: 30px; height: 30px;margin:5px 0" src="../../assets/img/ticket3.png" />
                            </div>
                            <div>
                                <div>
                                    <span style="font-size:15px;margin-left:6px;">{{"淘邦（5）"}}</span>
                                </div>
                                <div>
                                    <span style="font-size:15px;margin-left:6px;">{{"1511595****"}}</span>
                                </div>
                                <div>
                                    <span style="font-size:15px;margin-left:6px;">{{"深圳市宝安区恒达通物流园A1栋101（5）"}}</span>
                                </div>
                            </div>
                            <div v-if="data.ticketDto.orderOrigin != 1" style="font-size: 30px;font-weight:bold;border:1px solid #000;border-style: none none none none;height:80px;width:85px;text-align:center;">{{dicts.expCompanyCode.find(d => d.label === data.ticketDto.expCompany)?dicts.expCompanyCode.find(d => d.label === data.ticketDto.expCompany).value:""}}
                                <div style="position: relative;top: -5px;">{{data.ticketDto.shortTransport}}</div>
                            </div>
                            <div v-else-if="data.ticketDto.orderOrigin == 1 && data.ticketDto.cod==1" style="font-size: 30px;font-weight:bold;border:1px solid #000;border-style: solid solid solid solid;height:80px;width:85px;text-align:center;">{{dicts.expCompanyCode.find(d => d.label === data.ticketDto.expCompany)?dicts.expCompanyCode.find(d => d.label === data.ticketDto.expCompany).value:""}}
                                <div style="position: relative;top: -5px;">{{data.ticketDto.shortTransport}}</div>
                            </div>
                            <div v-else-if="data.ticketDto.orderOrigin == 1 && data.ticketDto.cod==3" style="font-size: 30px;font-weight:bold;border:1px solid #000;border-style: none none solid solid;height:80px;width:85px;text-align:center;">{{dicts.expCompanyCode.find(d => d.label === data.ticketDto.expCompany)?dicts.expCompanyCode.find(d => d.label === data.ticketDto.expCompany).value:""}}
                                <div style="position: relative;top: -5px;">{{data.ticketDto.shortTransport}}</div>
                            </div>
                        </div>
                        <div class="dotted-line2"></div>
                        <div class="invoice-header" style="margin-top:12px;">
                            <div class="barcode-warp">
                                <img :src="'data:image/png;base64,'+data.ticketDto.barcodeAsBase64Long" />
                                <div style="margin-top:5px;">{{data.ticketDto.code?data.ticketDto.code:''}}</div>
                            </div>
                        </div>
                        <div class="dotted-line2"></div>
                        <div class="invoice-header2" style="margin:10px 0;">
                            <div>
                                <div v-if="user.deptPath.indexOf('/1/8244/8245')>-1 || user.deptPath.indexOf('/1/7276/7278')>-1" style="margin-top:0px;">
                                    <span style="font-size:20px;margin-left: 300px;">{{"已驗視"}}</span>
                                </div>
                                <div v-else style="margin-top:0px;">
                                    <span style="font-size:20px;margin-left: 300px;">{{"已验视"}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="invoice-header" style="margin-top:15px;">
                            <div class="barcode-warp">
                                <img :src="'data:image/png;base64,'+data.ticketDto.barcodeAsBase64" />
                                <div style="margin-top:5px;">{{data.ticketDto.code?data.ticketDto.code:''}}</div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="barcode-warp" style="margin-top:15px;">
                            <img :src="'data:image/png;base64,'+data.ticketDto.barcodeAsBase64Long" />
                            <div style="margin-top:0px;">{{data.ticketDto.code?data.ticketDto.code:''}}</div>
                        </div>
                        <div class="invoice-header1" style="margin-bottom:-7px;padding-top:20px;margin-top: 20px;">
                            <div class="company">
                                <span style="font-size: 25px;position: relative;top: -10px;left: 10px;">{{"香港順豐"}}</span>
                                <span style="font-size: 10px;position: relative;top: 15px;left: -73px;">{{data.ticketDto.created}}</span>
                                <span style="font-size: 10px;position: relative;top: 20px;left: -23px;">第 1 / 1 個</span>
                            </div>
                            <span v-if="data.ticketDto.orderOrigin != 1" style="font-size: 22px;position: relative;top: 0px;left: -30px;border:1px solid #000;border-style: none none none none;">{{dicts.expCompanyCode.find(d => d.label === data.ticketDto.expCompany)?dicts.expCompanyCode.find(d => d.label === data.ticketDto.expCompany).value:""}}</span>
                            <span v-else-if="data.ticketDto.orderOrigin == 1 && data.ticketDto.cod==1" style="font-size: 22px;position: relative;top: 0px;left: -30px;border:1px solid #000;border-style: solid solid solid solid;">{{dicts.expCompanyCode.find(d => d.label === data.ticketDto.expCompany)?dicts.expCompanyCode.find(d => d.label === data.ticketDto.expCompany).value:""}}</span>
                            <span v-else-if="data.ticketDto.orderOrigin == 1 && data.ticketDto.cod==3" style="font-size: 22px;position: relative;top: 0px;left: -30px;border:1px solid #000;border-style: none none solid solid;">{{dicts.expCompanyCode.find(d => d.label === data.ticketDto.expCompany)?dicts.expCompanyCode.find(d => d.label === data.ticketDto.expCompany).value:""}}</span>
                            <span style="font-size: 25px;position: relative;top: -10px;left: 25px;">{{data.ticketDto.shortTransport}}</span>
                            <span style="font-size: 15px;position: relative;top: 25px;left: 26px;">{{"富嘉工業區"}}</span>
                            <div style="position: relative;width:30px;height:30px;font-size: 12px;border:1px solid #000;top:-5px;left:-25px;">{{"快  遞"}}<div style="margin-top:-5px;">{{"包  裹"}}</div></div>
                        </div>
                        <div style="position: relative;height:21px;width:37px;background-color:black;top: 0px;left: 254px;"><span style="color:white;font-size: 15px;position: relative;left: 11px;">未</span></div>
                        <div class="dotted-line2" style="margin-top:1px;"></div>
                        <div class="invoice-header" style="margin-top:2px;padding-bottom: 0px;">
                            <div class="barcode-warp">
                                <img v-if="data.ticketDto.expCode == '查询码不存在'" :src="'data:image/png;base64,'+data.ticketDto.barcodeAsBase64" />
                                <img v-else :src="'data:image/png;base64,'+data.ticketDto.barexpCodeAs64" />
                                <div v-if="data.ticketDto.expCode == '查询码不存在'" style="margin-top:0px;">{{data.ticketDto.code?data.ticketDto.code:''}}</div>
                                <div v-else style="margin-top:0px;">{{data.ticketDto.expCode}}</div>
                            </div>
                        </div>
                        <table style="width:100%; border-collapse:0;border-spacing:0; border-right: none; border-bottom: none;">
                            <tr>
                                <td style="vertical-align:middle; border-left:none;" ><span style="background-color: black; display:inline-block; padding: 3px; color: white; font-size: 15px; margin-left:8px;">收</span></td>
                                <td style="font-size:10px;  border-left:none;">
                                    <div style="font-size:10px;">{{"順運國際"}}
                                    </div>
                                    <div style="font-size:10px;">{{"85236977622"}}</div>
                                    <div style="font-size:10px;">{{"香港島南區香港仔大道234號富嘉工業區"}}</div>
                                </td>
                                <span style="font-size:12px;position:absolute;top:245px;left: 220px;">{{data.ticketDto.receiver}}</span>
                                <td rowspan="2" style="text-align:center; padding-top: 5px;">
                                    <img style="width: 70px; height: 70px" :src="'data:image/png;base64,'+data.ticketDto.qrCode" />
                                    <div style="font-size: 20px;">{{data.ticketDto.code.substring(data.ticketDto.code.length-4)}}</div>
                                </td>
                            </tr>
                            <tr>
                                <td style="vertical-align:middle; border-left:none;"><span style="background-color: black; display:inline-block; padding: 3px; color: white; font-size: 15px; margin-left:8px;">寄</span></td>
                                <td style="  border-left:none;">
                                    <div style="font-size:10px;">{{"黃明旺  1****26603"}}</div>
                                    <div style="font-size:10px;">{{"**********************************************"}}</div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <!-- <div v-else>
                        <div class="invoice-header" style="margin-top:2px;padding-bottom: 20px;">
                            <div class="barcode-warp" style="padding:5px 5px;">
                                <img v-if="data.ticketDto.expCode == '查询码不存在'" :src="'data:image/png;base64,'+data.ticketDto.barcodeAsBase64Long" />
                                <img v-else :src="'data:image/png;base64,'+data.ticketDto.barexpCodeAs64" />
                                <div v-if="data.ticketDto.expCode == '查询码不存在'" style="margin-top:0px;">{{data.ticketDto.code?data.ticketDto.code:''}}</div>
                                <div v-else style="margin-top:0px;">{{data.ticketDto.expCode}}</div>
                            </div>
                        </div>
                        <div class="dotted-line2"></div>
                        <div class="invoice-header" style="margin-top:2px;padding-bottom: 0px;">
                            <div class="barcode-warp" style="padding:10px 5px;">
                                <span v-if="data.ticketDto.orderOrigin != 1" style="font-size: 28px;position: relative;left: -80px;border:1px solid #000;padding:3px;border-style: none none none none;">{{dicts.expCompanyCode.find(d => d.label === data.ticketDto.expCompany)?dicts.expCompanyCode.find(d => d.label === data.ticketDto.expCompany).value:""}}</span>
                                <span v-else-if="data.ticketDto.orderOrigin == 1 && data.ticketDto.cod==1" style="font-size: 28px;position: relative;left: -80px;border:1px solid #000;padding:3px;border-style: solid solid solid solid;">{{dicts.expCompanyCode.find(d => d.label === data.ticketDto.expCompany)?dicts.expCompanyCode.find(d => d.label === data.ticketDto.expCompany).value:""}}</span>
                                <span v-else-if="data.ticketDto.orderOrigin == 1 && data.ticketDto.cod==3" style="font-size: 28px;position: relative;left: -80px;border:1px solid #000;padding:3px;border-style: none none solid solid;">{{dicts.expCompanyCode.find(d => d.label === data.ticketDto.expCompany)?dicts.expCompanyCode.find(d => d.label === data.ticketDto.expCompany).value:""}}</span>
                                <span style="font-size: 28px;position: relative;left: 25px;">圆通HB</span>
                                <span style="font-size: 28px;position: relative;left: 25px;">{{data.ticketDto.code.substring(data.ticketDto.code.length-4)}}</span>
                            </div>
                        </div>
                        <table style="width:100%; border-collapse:0;border-spacing:0; border-right: none; border-bottom: none;">
                            <tr>
                                <td style="font-size:15px;  border-left:none;padding:10px 15px;">
                                    <div style="">{{"广东省深圳市"}}<span style="font-size: 20px;position: relative;left: 140px;top:0px;">{{"SZ-08宝安分拨"}}</span></div>
                                    <div style="">{{"签收：顺达国际"}}</div>
                                    <div style="">{{"1356024****"}}<span style="font-size: 20px;position: relative;top: 0px;left: 230px;">{{data.ticketDto.shortTransport}}</span></div>
                                    <div style="">{{"深圳市宝安区恒达通物流园A1栋101"}}</div>
                                </td>
                            </tr>
                            <tr>
                                <td style="border-left:none; padding:10px 15px;">
                                    <div style="font-size:18px;">{{"件数：1件"}}<span style="font-size: 20px;position: relative;left: 220px;top:0px;">{{"已查验"}}</span></div>
                                    <div style="font-size:13px;padding-top:10px;">{{"本次服务使用圆通官网（www.yto.net.cn）公司的快递服务协议条款，您对此单的签收代表您已经收到快件且包装完好无损。"}}</div>
                                </td>
                            </tr>
                        </table>
                        <div class="dotted-line2"></div>
                        <div class="barcode-warp" style="padding:5px 5px;margin-top:15px;">
                            <img :src="'data:image/png;base64,'+data.ticketDto.barcodeAsBase64Long" />
                            <div style="margin-top:0px;">{{data.ticketDto.code?data.ticketDto.code:''}}</div>
                        </div>
                    </div> -->
                </div>
                <!--end invoice-box-->

                <!--打印面单-->
                <div class="next-page a1" v-if="data.pageDto && data.ticketDto"></div>
                <div v-if="data.pageDto && data.pageDto.pageAsBase64" class="vw400 exp-page">
                    <span v-if="data.pageDto">
                        <div style="position:relative">
                            <img :style="getStyle(data.pageDto)" :src="data.pageDto.pageAsBase64"/>
                            <div><span style="position:absolute;top:0px;left:22px;font-size:20px;font-weight:bolder;">{{data.pageDto.storeTag}}</span></div>
                        </div>
                        <!-- <span class="codeSpan-qj" v-else-if="data.pageDto.platform==2&& data.pageDto.expCompany == '全家'">{{data.pageDto.code}}</span> -->
                    </span>
                </div>

                <div class="next-page a2" v-if="data.tagDto && (data.pageDto || data.ticketDto)"></div>
                <!--2-打印入仓标签-->
                <div v-if="ctrls.indexOf(2)>-1" class="vw200">
                    <div style="margin-top:20px;font-size: 35px; font-weight:bold; text-align:center; line-height:100%;">{{data.tagDto.shelfCode}}</div>
                    <img style="max-width:95%; margin-left:5px; margin-top:5px;" :src="data.tagDto.barcodeAsBase64"/>
                    <div style="font-size: 20px; text-align:center; line-height:100%;">{{data.tagDto.code}}</div>
                </div>

                <!--3-打印货物图片-->
                <div class="next-page a3" v-if="data.thumbs && (data.tagDto || data.pageDto || data.ticketDto)"></div>
                <div v-if="ctrls.indexOf(3)>-1" class="img-wrap">
                    <div v-for="(thumb, idx1) in data.thumbs" :key="idx1">
                        <div style="margin-top:4px; margin-bottom:4px; text-align:center;">{{thumb.code+"("+thumb.detailId+")"}}</div>
                        <img class="product-img" :src="thumb.thumb" />
                        <div class="next-page a4" v-if="data.thumbs.length>1 && data.thumbs.length-1 > idx1"></div>
                    </div>
                </div>

                <!--5-打印上架单-->
                <div class="next-page a6" v-if="data.shelfCodeDto && ctrls.indexOf(5)>-1 && (data.tagDto || data.pageDto || data.ticketDto || data.thumbs)"></div>
                <div v-if="ctrls.indexOf(5)>-1" class="vw200" >
                    <div style="margin-top:20px;font-size: 35px; font-weight:bold; text-align:center; line-height:100%;">{{data.shelfCodeDto.shelfCode}}</div>
                    <img style="max-width:95%; margin-left:5px;margin-top:5px;" :src="data.shelfCodeDto.barcodeAsBase64" />
                    <div style="font-size: 20px; text-align:center; line-height:100%;">{{data.shelfCodeDto.code}}</div>
                </div>
            
                <!--6打印报关资料-->
                <div class="next-page a7" v-if="data.declareDto && ctrls.indexOf(6)>-1 && (data.tagDto || data.pageDto || data.ticketDto || data.thumbs || data.shelfCodeDto)"></div>
                <declare-print-tpl class="declare" v-if="ctrls.indexOf(6)>-1" :datas="[data.declareDto]"/>

                <!--打印查询码-->
                <div class="next-page a8" v-if="data.expTagDto && ctrls.indexOf(7)>-1 && (data.tagDto || data.pageDto || data.ticketDto || data.thumbs || data.shelfCodeDto || data.expTagDto)"></div>
                <div v-if="ctrls.indexOf(7)>-1" class="vw200" >
                    <img v-if="printType" style="max-width:65%; margin-left:35px;margin-top:5px;" :src="'data:image/png;base64,'+data.expTagDto.qrExpCode" />
                    <img v-else style="max-width:95%; margin-left:5px;margin-top:5px;" :src="data.expTagDto.barcodeAs64" />
                    <div style="font-size: 20px; text-align:center; line-height:100%;">{{data.expTagDto.expCode}}</div>
                    <div style="font-size: 20px; text-align:center; line-height:100%;">{{data.expTagDto.code.substring(0,data.expTagDto.code.length-4)}}<span style="font-size: 25px; font-weight:bold;">{{data.expTagDto.code.substring(data.expTagDto.code.length-4)}}</span></div>
                </div>
                
                <div class="next-page a5" v-if="datas.length>1 && index < datas.length-1"></div>
            </template>
        </div>
    </div>
</template>

<script>

// import JsBarcode from 'jsbarcode';
import DeclarePrintTpl from "../declare/PrintTpl.vue"
export default {
    name: "Ticket",
    props: {
        ctrls: Array, // 0-打印面单，1-打印货单，2-打印入仓标签, 3-打印找货图, 5-打印上架单，6-打印报关单
        datas: Array,
        ticketSetting: Object,
        printType:Boolean,
        user:Object
    },
    components:{
        DeclarePrintTpl: DeclarePrintTpl
    },
    computed: {
        blockStyle: { 
            get() {
                var inline = false;
                if(this.datas.length == 1){
                    var data = this.datas[0];
                    var total = 0;
                    total += data.ticketDto? 1 : 0;
                    total += data.tagDto? 1 : 0;
                    total += data.pageDto? 1 : 0;
                    total += (data.shelfCodeDto && this.ctrls.indexOf(5)>-1)? 1 : 0;
                    total += (data.declareDto && this.ctrls.indexOf(6)>-1)? 1 : 0;
                    total += (data.expTagDto && this.ctrls.indexOf(7)>-1)? 1 : 0;

                    inline = total == 1
                }else{
                    let total =0;
                    this.datas.forEach(data =>{
                        if(data.pageDto || data.tagDto || data.thumbs || data.ticketDto 
                            || (data.shelfCodeDto && this.ctrls.indexOf(5)>-1)
                            || (data.declareDto && this.ctrls.indexOf(6)>-1)
                            || (data.expTagDto && this.ctrls.indexOf(7)>-1)
                        ){
                            total +=1;
                        }
                    });

                    inline = total<=1;

                }
                return {
                    display: inline?"inline-block":"block",
                }
            }
        },
    },
    methods:{
        getStyle(dto){
            let style = {width: "400px"}
            if(dto.expCompany==='7-11'){
                if(dto.orderOrigin == 1){
                    style={width: '390px'}
                }else{
                    style = {width: "360px"};
                }
            }else if(dto.expCompany === '全家'){
                if(dto.orderOrigin == 1){
                    style={width: '390px'}
                }
                if(dto.platform != 1){
                    style={height: '595px'}
                }
            }
            return style;
        },
    }
};
</script>

<style lang="scss" scoped>

table{
    border-bottom: 1px solid #999;
    border-right: 1px solid #999;
}

table td, table th{
    border-left: 1px solid #999;
    border-top: 1px solid #999;
}

@media print{
    table{
        border-bottom: 1px solid #000;
        border-right: 1px solid #000;
    }

    table td, table th{
        border-left: 1px solid #000;
        border-top: 1px solid #000;
    }
}

.print-box {
    width: 100%;
    margin-top: 10px;
}
.invoice-box{
    position:relative;
    -webkit-print-color-adjust: exact;
}

.print-wrap {
    margin: 0px auto;
    // width: 400px;
}
.print-wrap1 {
    margin: 0px auto;
    // width: 300px;
}

.vw400{width:400px; margin:0px auto;}
.vw320{width:320px; margin:0px auto;}
.vw200{width:200px; margin:0px auto;}

.invoice-header {
    position: relative;
    text-align: center;
    line-height: 1;
    padding-bottom: 10px;
}
.invoice-header1{
    display:flex; 
    justify-content: space-between;
    align-items: center;
}
.invoice-header2{
    display:flex; 
    align-items: center;
}
.barcode-warp{
    text-align:center;
}
.invoice-header .goodtype1 {
    width: 30px;
    height: 30px;
    font-size: 30px;
    // position: absolute;
    left: 1px;
    top: 7px;
    line-height: 1;
    padding: 1px;
    border: 2px solid;
}
.invoice-header .goodtype0 {
    width: 30px;
    height: 30px;
    font-size: 30px;
    // position: absolute;
    left: 1px;
    top: 7px;
    line-height: 1;
    border: 2px solid;
    border-radius: 50%;
}
.invoice-header .offline1 {
    color: white;
    background: black;
}

.invoice-header1 .company {
    font-size: 30px;
    line-height: 1;
}
.invoice-remark {
    font-size: 14px;
    color: #333;
}
.dotted-line {
    border-bottom: 1px solid #000;
}
.dotted-line1 {
    border-bottom: 1px solid #000;
}
.dotted-line2 {
    border-bottom: 1px solid #000;
}
.print-wrap h3 {
    margin: 6px 0px;
    text-align: center;
    font-size: 16px;
}
.line-row {
    display: flex;
    font-size: 13px;
    color: #333;
    flex-wrap: nowrap;
}
.line-row .inline {
    flex: 0 0 50%;
}

.xline {
    font-size: 13px;
    display: flex;
    color: #333;
    flex-wrap: nowrap;
}

.xtable-wrap th {
    font-size: 14px;
    color: #333;
}
.xtable-wrap table {
    width: 100%;
    border-spacing: 0px;
    margin: 10px 0px;
}
.xtable-wrap td {
    font-size: 13px;
    padding-left: 3px;
    padding-right: 3px;
    color: #333;
}

.invoice-footer {
  font-size: 13px;
  color: #333;
  display: flex;
  justify-content: space-between;
}
.td-spec {
  border-bottom: 2px solid #666;
}
.next-page {
  page-break-before: always;
}
.after-page {
  page-break-after: always;
}
.exp-page {
  text-align: center;
  max-height:612px; 
  overflow: hidden;
}
.page711 {
  width: 340px;
}
.pageQJ{
  width: 400px;
  height: 600px;
}
.page-normal {
  width: 400px;
}

.tag-wrap {
  text-align: center;
  line-height: 1;
  font-size: 13px;
  color: #333;
}
.tag-owner {
  font-size: 16px;
  margin: 6px 0px;
}
.tag-detail {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}
.tag-date {
  text-align: right;
  margin-top: 10px;
  margin-bottom: 10px;
}
.product-img{
  width:100%;
}
.tag-header{display:flex; justify-content: space-around;}
.tag-title{font-size: 20px; font-weight:bold; margin-top:15px; padding-left:15px;}

.tag-line1{display:flex; justify-content: space-between; align-items: center;
  border-bottom: 2px black dashed;padding-bottom: 5px;margin-bottom:10px;
}
.tag-line2{display:flex; justify-content: space-between; align-items: center;
  /* padding-bottom: 5px;margin-bottom:10px;*/
  border-top: 2px solid black;
  margin-top: 4px;
}
.tag-shelf{font-size:20px; font-weight: bold;}

.tag-table{width:100%; border-collapse: collapse; 
 
}
.tag-table td{padding: 5px 0px;}
.tag-table td, .tag-table th {
  padding:2px;
}

.img-wrap{
  max-width:100%;
  max-height:100%;
}

.product-title{text-align:left; line-height: 140%;}
// .codeSpan-qj{
//   position: relative;
//   left: -66px;
//     top: -433px;
//   z-index:1;
// }
// .codeSpan-711{
//   position: relative;
//   left: -100px;
//   top: -17px;
//   z-index:1;
//   font-size: 20px;
//   font-weight:bold
// }
</style>
