<!--打印模板-->
<template>
    <div
        class="print-box"
        v-if="ctrls && ctrls.length>0"
        :style="blockStyle"
    >   
        <div class="print-wrap" v-for="(data, index) in datas" :key="index">
            <!--1-打印货单-->
            <template v-if="data.pageDto || data.tagDto || data.thumbs || data.ticketDto || (data.shelfCodeDto && ctrls.indexOf(5)>-1) || ctrls.indexOf(6)>-1 || ctrls.indexOf(7)>-1">
                <div v-if="ctrls.indexOf(1)>-1" class="vw400 invoice-box">
                    <div v-if="user.deptPath.indexOf('/1/7077/7078')>-1" class="invoice-header" style="text-align:center;margin-top:1px;font-size: 35px; font-weight:bold;">
                        <span>億隆運通</span>
                    </div>
                    <div class="invoice-header">
                        <div class="barcode-warp">
                            <img v-if="printType" :src="'data:image/png;base64,'+data.ticketDto.qrCode" />
                            <img v-else :src="'data:image/png;base64,'+data.ticketDto.barcodeAsBase64Long" />
                            <div style="margin-top:5px;">{{data.ticketDto.code?data.ticketDto.code:''}}</div>
                        </div>
                    </div>
                    <div class="invoice-header1">
                        <div class="company">
                            {{dicts.expCompanyCode.find(d => d.label === data.ticketDto.expCompany)?dicts.expCompanyCode.find(d => d.label === data.ticketDto.expCompany).value:""}}
                            <!-- {{(data.ticketDto.orderOrigin == 1 && data.ticketDto.expCompany!='黑貓')?"線下":""}} -->
                            <!-- {{data.ticketDto.cod == 1 && data.ticketDto.expCompany=='黑貓'?"貨到付款":""}} -->
                        </div>
                        <!-- <div v-if="data.ticketDto.expGoodType && data.ticketDto.expGoodType == 1" class='goodtype1'>特</div> -->
                        <div v-if="data.ticketDto.transport" style="font-size: 25px;">{{data.ticketDto.shortTransport}}</div>
                        <div>{{data.ticketDto.orderQty}}({{data.ticketDto.detailQty}})</div>
                        <div style="font-size: 30px; font-weight:bold;">{{data.ticketDto.code.substring(data.ticketDto.code.length-4)}}</div>
                    </div>
                    <div v-if="data.ticketDto.orderOrigin == 1 && data.ticketDto.cod==3" class="dotted-line"></div>
                    <div v-else-if="data.ticketDto.orderOrigin == 1 && data.ticketDto.cod==1" class="dotted-line1"></div>
                    <div v-if="data.ticketDto.orderOrigin == 1 && data.ticketDto.cod==1" style="margin-top:3px;" class="dotted-line1"></div>
                    <div v-show="ticketSetting.detailRemark1" class="invoice-remark" v-if="data.ticketDto.remark1">報單備註：{{data.ticketDto.remark1}}</div>
                    <div v-show="ticketSetting.detailRemark2" class="invoice-remark" v-if="data.ticketDto.remark2">貨單備註：{{data.ticketDto.remark2}}</div>
                    <div v-show="ticketSetting.note" class="invoice-remark" v-if="data.ticketDto.note">訂單備註：{{data.ticketDto.note}}</div>
                    <div v-show="ticketSetting.shopSellerMsg" class="invoice-remark" v-if="data.ticketDto.shopSellerMsg">留言：{{data.ticketDto.shopSellerMsg}}</div>
                    <div v-show="ticketSetting.pkgType" class="invoice-remark" style="font-weight:bold;font-size:16px;" v-if="data.ticketDto.pkgType">{{data.ticketDto.pkgType}}&nbsp;&nbsp;&nbsp;&nbsp;{{data.ticketDto.packType}}</div>
                    <div v-show="ticketSetting.shopName" class="invoice-remark" v-if="data.ticketDto.shopName">店鋪：{{data.ticketDto.shopName}}</div>
                    <div v-show="ticketSetting.ownerName" class="invoice-remark" v-if="data.ticketDto.ownerName">歸屬人：{{data.ticketDto.ownerName}}</div>
                    <div v-show="ticketSetting.receiver" class="invoice-remark" v-if="data.ticketDto.receiver">收件人：{{data.ticketDto.receiver}}</div>
                    <div v-show="ticketSetting.expGoodType" class="invoice-remark" v-if="data.ticketDto.expGoodType">貨物類型：{{data.ticketDto.expGoodType==1?"特貨":"普貨"}}</div>
                    <div v-show="ticketSetting.orderPlatform" class="invoice-remark" v-if="data.ticketDto.orderPlatform">訂單賣場：{{data.ticketDto.orderPlatform}}</div>
                    <!-- <div v-show="data.ticketDto.showReceiver" class="invoice-header1">
                        <div style="font-weight:bold;font-size:18px;">收件人：{{data.ticketDto.receiver}} </div>
                        <div style="font-weight:bold;font-size:18px;">归属人：{{data.ticketDto.sender}}</div>
                    </div> -->
                    <div class="xtable-wrap" v-show="ticketSetting.detail">
                        <table class="orders">
                            <tr>
                                <th v-show="ticketSetting.detailName">貨物品名</th>
                                <th v-show="ticketSetting.detailCode">貨物編號</th>
                                <th v-show="ticketSetting.detailShelfCode">貨物架位</th>
                            </tr>
                            <!--[快递单号，货架号，件数，品名，商品名，规格]-->
                            <template v-for="(order, indexa) in data.ticketDto.details">
                                <tr :key="indexa+'1'+2" style="height: 20px;" v-show="ticketSetting.detailCode || ticketSetting.detailShelfCode || ticketSetting.detailName">
                                    <td class="td-qty" v-show="ticketSetting.detailName">
                                        <span>{{order[3]}}</span>
                                    </td>
                                    <td class="td-code" v-show="ticketSetting.detailCode">
                                        <span>{{order[0]}}</span>
                                    </td>
                                    <td class="td-shelf" v-show="ticketSetting.detailShelfCode">
                                        <span>{{order[1]}}</span>
                                    </td>
                                </tr>
                                <tr :key="indexa" v-show="ticketSetting.detailTitle">
                                    <td colspan="3" class="td-name">
                                        <span style="font-weight:bold;font-size:15px">描述：</span>{{order[4]}}
                                    </td>
                                </tr>
                                <tr :key="indexa+'1'+1" v-show="ticketSetting.detailSpec">
                                    <td colspan="3" class="td-name" style="padding: 5px 0;">
                                        <span style="font-weight:bold;font-size:15px;padding-left:3px;">規格：{{order[5]}}</span><span>&nbsp;&nbsp;&nbsp;&nbsp;<span style="font-weight:bold;">///&nbsp;&nbsp;&nbsp;&nbsp;件數：</span><span style="font-size:20px"><strong>{{order[2]}}</strong></span></span>
                                        <span v-show="ticketSetting.detailStoreType">&nbsp;&nbsp;&nbsp;&nbsp;{{order[7]}}</span>
                                        <!-- <span>&nbsp;&nbsp;&nbsp;&nbsp;///&nbsp;&nbsp;&nbsp;&nbsp;库存编号：{{order[6]}}</span> -->
                                    </td>
                                </tr>
                                <tr :key="indexa+'2'+1" v-show="ticketSetting.detailSku">
                                    <td colspan="3" class="td-name">
                                        <span style="font-weight:bold;font-size:15px">SKU：{{order[8]}}</span>
                                    </td>
                                </tr>
                                <tr :key="indexa+'3'+1" v-show="ticketSetting.detailSku">
                                    <td colspan="3" class="td-name">
                                        <span style="font-weight:bold;font-size:15px">貨號：{{order[9]}}</span><span>&nbsp;&nbsp;&nbsp;&nbsp;<span style="font-weight:bold;">///&nbsp;&nbsp;&nbsp;&nbsp;件數：</span><span style="font-size:20px"><strong>{{order[2]}}</strong></span></span>
                                    </td>
                                </tr>
                            </template>
                        </table>
                    </div>
                    <!--end orders-->
                    <div class="invoice-footer">
                        <span v-show="ticketSetting.detailPkg">{{data.ticketDto.total}} / {{data.ticketDto.pkgCode}}</span>
                        <span v-show="ticketSetting.detailDate" class="date">{{data.ticketDto.created.substring(5)}}/{{new Date().getMonth()+1 + "-" + new Date().getDate()}}
                        <!-- <span style="display:inline-block; margin-left:5px; font-size:18px; font-weight:bold;">已驗視</span> -->
                        </span>
                    </div>
                </div>
                <!--end invoice-box-->

                <!--打印面单-->
                <div class="next-page a1" v-if="data.pageDto && data.ticketDto"></div>
                <div v-if="data.pageDto && data.pageDto.pageAsBase64" class="vw400 exp-page">
                    <span v-if="data.pageDto">
                        <div style="position:relative">
                            <img :style="getStyle(data.pageDto)" :src="data.pageDto.pageAsBase64"/>
                            <div><span style="position:absolute;top:0px;left:22px;font-size:20px;font-weight:bolder;">{{data.pageDto.storeTag}}</span></div>
                        </div>
                        <!-- <span class="codeSpan-qj" v-else-if="data.pageDto.platform==2&& data.pageDto.expCompany == '全家'">{{data.pageDto.code}}</span> -->
                    </span>
                </div>

                <div class="next-page a2" v-if="data.tagDto && (data.pageDto || data.ticketDto)"></div>
                <!--2-打印入仓标签-->
                <div v-if="ctrls.indexOf(2)>-1" class="vw200">
                    <div style="margin-top:20px;font-size: 35px; font-weight:bold; text-align:center; line-height:100%;">{{data.tagDto.shelfCode}}</div>
                    <img style="max-width:95%; margin-left:5px; margin-top:5px;" :src="data.tagDto.barcodeAsBase64"/>
                    <div style="font-size: 20px; text-align:center; line-height:100%;">{{data.tagDto.code}}</div>
                </div>

                <!--3-打印货物图片-->
                <div class="next-page a3" v-if="data.thumbs && (data.tagDto || data.pageDto || data.ticketDto)"></div>
                <div v-if="ctrls.indexOf(3)>-1" class="img-wrap">
                    <div v-for="(thumb, idx1) in data.thumbs" :key="idx1">
                        <div style="margin-top:4px; margin-bottom:4px; text-align:center;">{{thumb.code+"("+thumb.detailId+")"}}</div>
                        <img class="product-img" :src="thumb.thumb" />
                        <div class="next-page a4" v-if="data.thumbs.length>1 && data.thumbs.length-1 > idx1"></div>
                    </div>
                </div>

                <!--5-打印上架单-->
                <div class="next-page a6" v-if="data.shelfCodeDto && ctrls.indexOf(5)>-1 && (data.tagDto || data.pageDto || data.ticketDto || data.thumbs)"></div>
                <div v-if="ctrls.indexOf(5)>-1" class="vw200" >
                    <div style="margin-top:20px;font-size: 35px; font-weight:bold; text-align:center; line-height:100%;">{{data.shelfCodeDto.shelfCode}}</div>
                    <img style="max-width:95%; margin-left:5px;margin-top:5px;" :src="data.shelfCodeDto.barcodeAsBase64" />
                    <div style="font-size: 20px; text-align:center; line-height:100%;">{{data.shelfCodeDto.code}}</div>
                </div>
            
                <!--打印报关资料-->
                <div class="next-page a7" v-if="data.declareDto && ctrls.indexOf(6)>-1 && (data.tagDto || data.pageDto || data.ticketDto || data.thumbs || data.shelfCodeDto)"></div>
                <declare-print-tpl class="declare" v-if="ctrls.indexOf(6)>-1" :datas="[data.declareDto]"/>

                <!--打印查询码-->
                <div class="next-page a8" v-if="data.expTagDto && ctrls.indexOf(7)>-1 && (data.tagDto || data.pageDto || data.ticketDto || data.thumbs || data.shelfCodeDto || data.expTagDto)"></div>
                <div v-if="ctrls.indexOf(7)>-1" class="vw200" >
                    <img v-if="printType" style="max-width:65%; margin-left:35px;margin-top:5px;" :src="'data:image/png;base64,'+data.expTagDto.qrExpCode" />
                    <img v-else style="max-width:95%; margin-left:5px;margin-top:5px;" :src="data.expTagDto.barcodeAs64" />
                    <div style="font-size: 20px; text-align:center; line-height:100%;">{{data.expTagDto.expCode}}</div>
                    <div style="font-size: 20px; text-align:center; line-height:100%;">{{data.expTagDto.code.substring(0,data.expTagDto.code.length-4)}}<span style="font-size: 25px; font-weight:bold;">{{data.expTagDto.code.substring(data.expTagDto.code.length-4)}}</span></div>
                </div>

                <div class="next-page a5" v-if="datas.length>1 && index < datas.length-1"></div>
            </template>
        </div>
    </div>
</template>

<script>

// import JsBarcode from 'jsbarcode';
import DeclarePrintTpl from "../declare/PrintTpl.vue"
export default {
    name: "Ticket",
    props: {
        ctrls: Array, // 0-打印面单，1-打印货单，2-打印入仓标签, 3-打印找货图, 5-打印上架单，6-打印报关单
        datas: Array,
        ticketSetting: Object,
        printType:Boolean,
        user:Object
    },
    components:{
        DeclarePrintTpl: DeclarePrintTpl
    },
    computed: {
        blockStyle: { 
            get() {
                var inline = false;
                if(this.datas.length == 1){
                    var data = this.datas[0];
                    var total = 0;
                    total += data.ticketDto? 1 : 0;
                    total += data.tagDto? 1 : 0;
                    // total += (data.pageDto || this.ctrls.indexOf(0)>-1)? 1 : 0;
                    total += data.pageDto? 1 : 0;
                    total += (data.shelfCodeDto && this.ctrls.indexOf(5)>-1)? 1 : 0;
                    total += (data.declareDto && this.ctrls.indexOf(6)>-1)? 1 : 0;
                    total += (data.expTagDto && this.ctrls.indexOf(7)>-1)? 1 : 0;

                    inline = total == 1
                }else{
                    let total =0;
                    this.datas.forEach(data =>{
                        if(data.pageDto || data.tagDto || data.thumbs || data.ticketDto 
                            || (data.shelfCodeDto && this.ctrls.indexOf(5)>-1)
                            || (data.declareDto && this.ctrls.indexOf(6)>-1)
                            || (data.expTagDto && this.ctrls.indexOf(7)>-1)
                        ){
                            total +=1;
                        }
                    });

                    inline = total<=1;

                }
                return {
                    display: inline?"inline-block":"block",
                }
            }
        },
    },
    methods:{
        getStyle(dto){
            let style = {width: "400px"}
            if(dto.expCompany==='7-11'){
                if(dto.orderOrigin == 1){
                    style={width: '390px'}
                }else{
                    style = {width: "360px"};
                }
            }else if(dto.expCompany === '全家'){
                if(dto.orderOrigin == 1){
                    style={width: '390px'}
                }
                if(dto.platform != 1){
                    style={height: '595px'}
                }
            }
            return style;
        },
    }
};
</script>

<style lang="scss" scoped>

table{
    border-bottom: 1px solid #999;
    border-right: 1px solid #999;
}

table td, table th{
    border-left: 1px solid #999;
    border-top: 1px solid #999;
}

@media print{
    table{
        border-bottom: 1px solid #000;
        border-right: 1px solid #000;
    }

    table td, table th{
        border-left: 1px solid #000;
        border-top: 1px solid #000;
    }
}

.print-box {
    width: 100%;
    margin-top: 10px;
}
.invoice-box{
    position:relative;
    -webkit-print-color-adjust: exact;
}

.print-wrap {
    margin: 0px auto;
    // width: 400px;
}
.print-wrap1 {
    margin: 0px auto;
    // width: 300px;
}

.vw400{width:400px; margin:0px auto;}
.vw320{width:320px; margin:0px auto;}
.vw200{width:200px; margin:0px auto;}

.invoice-header {
    position: relative;
    text-align: center;
    line-height: 1;
    padding-bottom: 10px;
}
.invoice-header1{
    display:flex; 
    justify-content: space-between;
    align-items: center;
}
.barcode-warp{
    text-align:center;
}
.invoice-header .goodtype1 {
    width: 30px;
    height: 30px;
    font-size: 30px;
    position: absolute;
    left: 1px;
    top: 7px;
    line-height: 1;
    padding: 1px;
    border: 2px solid;
}
.invoice-header .goodtype0 {
    width: 30px;
    height: 30px;
    font-size: 30px;
    position: absolute;
    left: 1px;
    top: 7px;
    line-height: 1;
    border: 2px solid;
    border-radius: 50%;
}
.invoice-header .offline1 {
    color: white;
    background: black;
}

.invoice-header1 .company {
    font-size: 30px;
    line-height: 1;
}
.invoice-remark {
    font-size: 14px;
    color: #333;
}
.dotted-line {
    border-bottom: 2px dashed #000;
}
.dotted-line1 {
    border-bottom: 2px solid #000;
}
.print-wrap h3 {
    margin: 6px 0px;
    text-align: center;
    font-size: 16px;
}
.line-row {
    display: flex;
    font-size: 13px;
    color: #333;
    flex-wrap: nowrap;
}
.line-row .inline {
    flex: 0 0 50%;
}

.xline {
    font-size: 13px;
    display: flex;
    color: #333;
    flex-wrap: nowrap;
}

.xtable-wrap th {
    font-size: 14px;
    color: #333;
}
.xtable-wrap table {
    width: 100%;
    border-spacing: 0px;
    margin: 10px 0px;
}
.xtable-wrap td {
    font-size: 13px;
    padding-left: 3px;
    padding-right: 3px;
    color: #333;
}

.invoice-footer {
  font-size: 13px;
  color: #333;
  display: flex;
  justify-content: space-between;
}
.td-spec {
  border-bottom: 2px solid #666;
}
.next-page {
  page-break-before: always;
}
.after-page {
  page-break-after: always;
}
.exp-page {
  text-align: center;
  max-height:612px; 
  overflow: hidden;
}
.page711 {
  width: 340px;
}
.pageQJ{
  width: 400px;
  height: 600px;
}
.page-normal {
  width: 400px;
}

.tag-wrap {
  text-align: center;
  line-height: 1;
  font-size: 13px;
  color: #333;
}
.tag-owner {
  font-size: 16px;
  margin: 6px 0px;
}
.tag-detail {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}
.tag-date {
  text-align: right;
  margin-top: 10px;
  margin-bottom: 10px;
}
.product-img{
  width:100%;
}
.tag-header{display:flex; justify-content: space-around;}
.tag-title{font-size: 20px; font-weight:bold; margin-top:15px; padding-left:15px;}

.tag-line1{display:flex; justify-content: space-between; align-items: center;
  border-bottom: 2px black dashed;padding-bottom: 5px;margin-bottom:10px;
}
.tag-line2{display:flex; justify-content: space-between; align-items: center;
  /* padding-bottom: 5px;margin-bottom:10px;*/
  border-top: 2px solid black;
  margin-top: 4px;
}
.tag-shelf{font-size:20px; font-weight: bold;}

.tag-table{width:100%; border-collapse: collapse; 
 
}
.tag-table td{padding: 5px 0px;}
.tag-table td, .tag-table th {
  padding:2px;
}

.img-wrap{
  max-width:100%;
  max-height:100%;
}

.product-title{text-align:left; line-height: 140%;}
// .codeSpan-qj{
//   position: relative;
//   left: -66px;
//     top: -433px;
//   z-index:1;
// }
// .codeSpan-711{
//   position: relative;
//   left: -100px;
//   top: -17px;
//   z-index:1;
//   font-size: 20px;
//   font-weight:bold
// }
</style>
