<!--打印模板-->
<template>
    <div
        class="print-box"
        v-if="ctrls && ctrls.length>0"
        :style="blockStyle"
    >
        <div class="print-wrap" v-for="(data, index) in datas" :key="index">
            <!--1-打印出货码-->
            <template v-if="data.pageDto || data.tagDto || data.thumbs || data.ticketDto || (data.shelfCodeDto && ctrls.indexOf(5)>-1) || ctrls.indexOf(6)>-1 || ctrls.indexOf(7)>-1">
                <div v-if="ctrls.indexOf(1)>-1" class="vw400 invoice-box">
                    <!-- 岩盛通定制出货码样式 -->
                    <div v-if="user.deptPath.indexOf('/1/7276/7278') >= 0">
                        <div class="invoice-header" style="margin-top:12px;">
                        <div class="barcode-warp">
                            <img v-if="printType" :src="'data:image/png;base64,'+data.ticketDto.qrCode" />
                            <img v-else :src="'data:image/png;base64,'+data.ticketDto.barcodeAsBase64Long" />
                            <!-- <div style="margin-top:5px;">{{data.ticketDto.code?data.ticketDto.code:''}}</div> -->
                        </div>
                        </div>
                        <div class="invoice-header1">
                            <div class="company">
                                {{dicts.expCompanyCode.find(d => d.label === data.ticketDto.expCompany)?dicts.expCompanyCode.find(d => d.label === data.ticketDto.expCompany).value:""}}
                            </div>
                            <div style="font-size: 30px;font-weight:bold;">{{data.ticketDto.shortTransport}}</div>
                            <div style="font-size: 30px;font-weight:bold;">{{data.ticketDto.code.length>6?data.ticketDto.code.substring(data.ticketDto.code.length-6):data.ticketDto.code}}</div>
                        </div>
                        <div v-if="data.ticketDto.orderOrigin == 1 && data.ticketDto.cod==3" class="dotted-line"></div>
                        <div v-else-if="data.ticketDto.orderOrigin == 1 && data.ticketDto.cod==1" class="dotted-line1"></div>
                        <div v-if="data.ticketDto.orderOrigin == 1 && data.ticketDto.cod==1" style="margin-top:3px;" class="dotted-line1"></div>
                    </div>
                    <div v-else>
                        <div class="invoice-header" style="margin-top:12px;">
                            <div class="barcode-warp">
                                <img v-if="printType" :src="'data:image/png;base64,'+data.ticketDto.qrCode" />
                                <img v-else :src="'data:image/png;base64,'+data.ticketDto.barcodeAsBase64Long" />
                                <div style="margin-top:5px;">{{data.ticketDto.code?data.ticketDto.code:''}}</div>
                            </div>
                        </div>
                        <div class="invoice-header1">
                            <div class="company">
                                {{dicts.expCompanyCode.find(d => d.label === data.ticketDto.expCompany)?dicts.expCompanyCode.find(d => d.label === data.ticketDto.expCompany).value:""}}
                            </div>
                            <div style="font-size: 30px;font-weight:bold;">{{data.ticketDto.shortTransport}}</div>
                            <div style="font-size: 30px;font-weight:bold;">{{data.ticketDto.code.substring(data.ticketDto.code.length-4)}}</div>
                        </div>
                        <div v-if="data.ticketDto.orderOrigin == 1 && data.ticketDto.cod==3" class="dotted-line"></div>
                        <div v-else-if="data.ticketDto.orderOrigin == 1 && data.ticketDto.cod==1" class="dotted-line1"></div>
                        <div v-if="data.ticketDto.orderOrigin == 1 && data.ticketDto.cod==1" style="margin-top:3px;" class="dotted-line1"></div>
                    </div>
                </div>

                <!--打印面单-->
                <div class="next-page a1" v-if="data.pageDto && data.ticketDto"></div>
                <div v-if="data.pageDto && data.pageDto.pageAsBase64" class="vw400 exp-page">
                    <span v-if="data.pageDto">
                        <div style="position:relative">
                            <img :style="getStyle(data.pageDto)" :src="data.pageDto.pageAsBase64"/>
                            <div><span style="position:absolute;top:0px;left:22px;font-size:20px;font-weight:bolder;">{{data.pageDto.storeTag}}</span></div>
                        </div>
                        <!-- <span class="codeSpan-qj" v-else-if="data.pageDto.platform==2&& data.pageDto.expCompany == '全家'">{{data.pageDto.code}}</span> -->
                    </span>
                </div>

                <div class="next-page a2" v-if="data.tagDto && (data.pageDto || data.ticketDto)"></div>
                <!--2-打印入仓标签-->
                <div v-if="ctrls.indexOf(2)>-1" class="vw200">
                    <div style="margin-top:20px;font-size: 35px; font-weight:bold; text-align:center; line-height:100%;">{{data.tagDto.shelfCode}}</div>
                    <img style="max-width:95%; margin-left:5px; margin-top:5px;" :src="data.tagDto.barcodeAsBase64"/>
                    <div style="font-size: 20px; text-align:center; line-height:100%;">{{data.tagDto.code}}</div>
                </div>

                <!--3-打印货物图片-->
                <div class="next-page a3" v-if="data.thumbs && (data.tagDto || data.pageDto || data.ticketDto)"></div>
                <div v-if="ctrls.indexOf(3)>-1" class="img-wrap">
                    <div v-for="(thumb, idx1) in data.thumbs" :key="idx1">
                        <div style="margin-top:4px; margin-bottom:4px; text-align:center;">{{thumb.code+"("+thumb.detailId+")"}}</div>
                        <img class="product-img" :src="thumb.thumb" />
                        <div class="next-page a4" v-if="data.thumbs.length>1 && data.thumbs.length-1 > idx1"></div>
                    </div>
                </div>

                <!--5-打印上架单-->
                <div class="next-page a6" v-if="data.shelfCodeDto && ctrls.indexOf(5)>-1 && (data.tagDto || data.pageDto || data.ticketDto || data.thumbs)"></div>
                <div v-if="ctrls.indexOf(5)>-1" class="vw200" >
                    <div style="margin-top:20px;font-size: 35px; font-weight:bold; text-align:center; line-height:100%;">{{data.shelfCodeDto.shelfCode}}</div>
                    <img style="max-width:95%; margin-left:5px;margin-top:5px;" :src="data.shelfCodeDto.barcodeAsBase64" />
                    <div style="font-size: 20px; text-align:center; line-height:100%;">{{data.shelfCodeDto.code}}</div>
                </div>
            
                <!--打印报关资料-->
                <div class="next-page a7" v-if="data.declareDto && ctrls.indexOf(6)>-1 && (data.tagDto || data.pageDto || data.ticketDto || data.thumbs || data.shelfCodeDto)"></div>
                <declare-print-tpl class="declare" v-if="ctrls.indexOf(6)>-1" :datas="[data.declareDto]"/>

                <!--打印查询码-->
                <div class="next-page a8" v-if="data.expTagDto && ctrls.indexOf(7)>-1 && (data.tagDto || data.pageDto || data.ticketDto || data.thumbs || data.shelfCodeDto || data.expTagDto)"></div>
                <div v-if="ctrls.indexOf(7)>-1" class="vw200" >
                    <img v-if="printType" style="max-width:65%; margin-left:35px;margin-top:5px;" :src="'data:image/png;base64,'+data.expTagDto.qrExpCode" />
                    <img v-else style="max-width:95%; margin-left:5px;margin-top:5px;" :src="data.expTagDto.barcodeAs64" />
                    <div style="font-size: 20px; text-align:center; line-height:100%;">{{"dfads"}}</div>
                    <div style="font-size: 20px; text-align:center; line-height:100%;">{{data.expTagDto.expCode}}</div>
                    <div style="font-size: 20px; text-align:center; line-height:100%;">{{data.expTagDto.code.substring(0,data.expTagDto.code.length-4)}}<span style="font-size: 25px; font-weight:bold;">{{data.expTagDto.code.substring(data.expTagDto.code.length-4)}}</span></div>
                </div>

                <div class="next-page a5" v-if="datas.length>1 && index < datas.length-1"></div>
            </template>
        </div>
    </div>
</template>

<script>

// import JsBarcode from 'jsbarcode';
import DeclarePrintTpl from "../declare/PrintTpl.vue"
export default {
    name: "Ticket",
    props: {
        ctrls: Array, // 0-打印面单，1-打印货单，2-打印入仓标签, 3-打印找货图, 5-打印上架单，6-打印报关单
        datas: Array,
        ticketSetting: Object,
        printType:Boolean,
        user:Object
    },
    components:{
        DeclarePrintTpl: DeclarePrintTpl
    },
    computed: {
        blockStyle: { 
            get() {
                var inline = false;
                if(this.datas.length == 1){
                    var data = this.datas[0];
                    var total = 0;
                    total += data.ticketDto? 1 : 0;
                    total += data.tagDto? 1 : 0;
                    // total += (data.pageDto || this.ctrls.indexOf(0)>-1)? 1 : 0;
                    total += data.pageDto? 1 : 0;
                    total += (data.shelfCodeDto && this.ctrls.indexOf(5)>-1)? 1 : 0;
                    total += (data.declareDto && this.ctrls.indexOf(6)>-1)? 1 : 0;
                    total += (data.expTagDto && this.ctrls.indexOf(7)>-1)? 1 : 0;

                    inline = total == 1
                }else{
                    let total =0;
                    this.datas.forEach(data =>{
                        if(data.pageDto || data.tagDto || data.thumbs || data.ticketDto 
                            || (data.shelfCodeDto && this.ctrls.indexOf(5)>-1)
                            || (data.declareDto && this.ctrls.indexOf(6)>-1)
                            || (data.expTagDto && this.ctrls.indexOf(7)>-1)
                        ){
                            total +=1;
                        }
                    });

                    inline = total<=1;

                }
                return {
                    display: inline?"inline-block":"block",
                }
            }
        },
    },
    methods:{
        getStyle(dto){
            let style = {width: "400px"}
            if(dto.expCompany==='7-11'){
                if(dto.orderOrigin == 1){
                    style={width: '390px'}
                }else{
                    style = {width: "360px"};
                }
            }else if(dto.expCompany === '全家'){
                if(dto.orderOrigin == 1){
                    style={width: '390px'}
                }
                if(dto.platform != 1){
                    style={height: '595px'}
                }
            }
            return style;
        },
    }
};
</script>

<style lang="scss" scoped>

table{
    border-bottom: 1px solid #999;
    border-right: 1px solid #999;
}

table td, table th{
    border-left: 1px solid #999;
    border-top: 1px solid #999;
}

@media print{
    table{
        border-bottom: 1px solid #000;
        border-right: 1px solid #000;
    }

    table td, table th{
        border-left: 1px solid #000;
        border-top: 1px solid #000;
    }
}

.print-box {
    width: 100%;
    margin-top: 10px;
}
.invoice-box{
    position:relative;
    -webkit-print-color-adjust: exact;
}

.print-wrap {
    margin: 0px auto;
    // width: 400px;
}
.print-wrap1 {
    margin: 0px auto;
    // width: 300px;
}

.vw400{width:400px; margin:0px auto;}
.vw320{width:320px; margin:0px auto;}
.vw200{width:200px; margin:0px auto;}

.invoice-header {
    position: relative;
    text-align: center;
    line-height: 1;
    padding-bottom: 10px;
}
.invoice-header1{
    display:flex; 
    justify-content: space-between;
    align-items: center;
}
.invoice-header2{
    display:flex; 
    align-items: center;
}
.barcode-warp{
    text-align:center;
}
.invoice-header .goodtype1 {
    width: 30px;
    height: 30px;
    font-size: 30px;
    position: absolute;
    left: 1px;
    top: 7px;
    line-height: 1;
    padding: 1px;
    border: 2px solid;
}
.invoice-header .goodtype0 {
    width: 30px;
    height: 30px;
    font-size: 30px;
    position: absolute;
    left: 1px;
    top: 7px;
    line-height: 1;
    border: 2px solid;
    border-radius: 50%;
}
.invoice-header .offline1 {
    color: white;
    background: black;
}

.invoice-header1 .company {
    font-size: 30px;
    line-height: 1;
}
.invoice-remark {
    font-size: 14px;
    color: #333;
}
.dotted-line {
    border-bottom: 2px dashed #000;
}
.dotted-line1 {
    border-bottom: 2px solid #000;
}
.print-wrap h3 {
    margin: 6px 0px;
    text-align: center;
    font-size: 16px;
}
.line-row {
    display: flex;
    font-size: 13px;
    color: #333;
    flex-wrap: nowrap;
}
.line-row .inline {
    flex: 0 0 50%;
}

.xline {
    font-size: 13px;
    display: flex;
    color: #333;
    flex-wrap: nowrap;
}

.xtable-wrap th {
    font-size: 14px;
    color: #333;
}
.xtable-wrap table {
    width: 100%;
    border-spacing: 0px;
    margin: 10px 0px;
}
.xtable-wrap td {
    font-size: 13px;
    padding-left: 3px;
    padding-right: 3px;
    color: #333;
}

.invoice-footer {
  font-size: 13px;
  color: #333;
  display: flex;
  justify-content: space-between;
}
.td-spec {
  border-bottom: 2px solid #666;
}
.next-page {
  page-break-before: always;
}
.after-page {
  page-break-after: always;
}
.exp-page {
  text-align: center;
  max-height:612px; 
  overflow: hidden;
}
.page711 {
  width: 340px;
}
.pageQJ{
  width: 400px;
  height: 600px;
}
.page-normal {
  width: 400px;
}

.tag-wrap {
  text-align: center;
  line-height: 1;
  font-size: 13px;
  color: #333;
}
.tag-owner {
  font-size: 16px;
  margin: 6px 0px;
}
.tag-detail {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}
.tag-date {
  text-align: right;
  margin-top: 10px;
  margin-bottom: 10px;
}
.product-img{
  width:100%;
}
.tag-header{display:flex; justify-content: space-around;}
.tag-title{font-size: 20px; font-weight:bold; margin-top:15px; padding-left:15px;}

.tag-line1{display:flex; justify-content: space-between; align-items: center;
  border-bottom: 2px black dashed;padding-bottom: 5px;margin-bottom:10px;
}
.tag-line2{display:flex; justify-content: space-between; align-items: center;
  /* padding-bottom: 5px;margin-bottom:10px;*/
  border-top: 2px solid black;
  margin-top: 4px;
}
.tag-shelf{font-size:20px; font-weight: bold;}

.tag-table{width:100%; border-collapse: collapse; 
 
}
.tag-table td{padding: 5px 0px;}
.tag-table td, .tag-table th {
  padding:2px;
}

.img-wrap{
  max-width:100%;
  max-height:100%;
}
.product-title{text-align:left; line-height: 140%;}
// .codeSpan-qj{
//   position: relative;
//   left: -66px;
//     top: -433px;
//   z-index:1;
// }
// .codeSpan-711{
//   position: relative;
//   left: -100px;
//   top: -17px;
//   z-index:1;
//   font-size: 20px;
//   font-weight:bold
// }
</style>
