import wavDot from "../assets/media/dot.wav";
import wavShi from "../assets/media/shi.wav";
import wavBai from "../assets/media/bai.wav";
import wavQian from "../assets/media/qian.wav";
import wavWan from "../assets/media/wan.wav";
import fu from "../assets/media/fu.wav";
import wav0 from "../assets/media/0.wav";
import wav1 from "../assets/media/1.wav";
import wav2 from "../assets/media/2.wav";
import wav3 from "../assets/media/3.wav";
import wav4 from "../assets/media/4.wav";
import wav5 from "../assets/media/5.wav";
import wav6 from "../assets/media/6.wav";
import wav7 from "../assets/media/7.wav";
import wav8 from "../assets/media/8.wav";
import wav9 from "../assets/media/9.wav";

let PlayWeight = function(){
    
    var _this = this;
    this.wavs = [];
    this.sources = [
        wav0,
        wav1,
        wav2,
        wav3,
        wav4,
        wav5,
        wav6,
        wav7,
        wav8,
        wav9,
        wavDot,
        wavShi,
        wavBai,
        wavQian,
        wavWan,
        fu
    ];

    //获取blob格式的wav文件
    function getWavBlob(url, cb, i){
        var xhr = new XMLHttpRequest();
        xhr.open("get", url, true);
        xhr.responseType = "blob";
        xhr.onload  = function() {
            if (this.status == 200) {
                if(cb) cb(this.response, i);
            }
        };
        xhr.send();
    }

    //将wav文件转换成arrayBuffer
    function getWavAsArrayBuffer(blob, index){
        let reader  = new FileReader();
        reader.onload = function(e) {
            _this.wavs[index]=this.result;
        };
        reader.readAsArrayBuffer(blob);
    }
    
    getWavBlob(wav0, getWavAsArrayBuffer, 0);
    getWavBlob(wav1, getWavAsArrayBuffer, 1);
    getWavBlob(wav2, getWavAsArrayBuffer, 2);
    getWavBlob(wav3, getWavAsArrayBuffer, 3);
    getWavBlob(wav4, getWavAsArrayBuffer, 4);
    getWavBlob(wav5, getWavAsArrayBuffer, 5);
    getWavBlob(wav6, getWavAsArrayBuffer, 6);
    getWavBlob(wav7, getWavAsArrayBuffer, 7);
    getWavBlob(wav8, getWavAsArrayBuffer, 8);
    getWavBlob(wav9, getWavAsArrayBuffer, 9);
    getWavBlob(wavDot, getWavAsArrayBuffer, 10);
    getWavBlob(wavShi, getWavAsArrayBuffer, 11);
    getWavBlob(wavBai, getWavAsArrayBuffer, 12);
    getWavBlob(wavQian, getWavAsArrayBuffer, 13);
    getWavBlob(wavWan, getWavAsArrayBuffer, 14);
    getWavBlob(fu, getWavAsArrayBuffer, 15);

}

/**
 * 获取重量的语音文件
 * @param {Double} weight 
 */
PlayWeight.prototype.getWavMedia = function(weight){
    
    function convertToChinaNum(num) {
        var arr1 = new Array('零', '一', '二', '三', '四', '五', '六', '七', '八', '九');
        var arr2 = new Array('', '十', '百', '千', '万', '十', '百', '千', '亿', '十', '百', '千','万', '十', '百', '千','亿');//可继续追加更高位转换值
        if(!num || isNaN(num)){
            return "零";
        }
		var nums = num.toString().split(".");
		
        var english = nums[0].split("");
        var cacheEnglish = nums[0].split("");
        var result = "";
        if(english.indexOf('-') > -1){
            english = english.slice(1);
        }
        for (var i = 0; i < english.length; i++) {
            var des_i = english.length - 1 - i;//倒序排列设值
            result = arr2[i] + result;
            var arr1_index = english[des_i];
            result = arr1[arr1_index] + result;
        }
        if(cacheEnglish.indexOf('-') > -1){
            result = "负" + result;
        }
        //将【零千、零百】换成【零】 【十零】换成【十】
        result = result.replace(/零(千|百|十)/g, '零').replace(/十零/g, '十');
        //合并中间多个零为一个零
        result = result.replace(/零+/g, '零');
        //将【零亿】换成【亿】【零万】换成【万】
        result = result.replace(/零亿/g, '亿').replace(/零万/g, '万');
        //将【亿万】换成【亿】
        result = result.replace(/亿万/g, '亿');
        //移除末尾的零
        result = result.replace(/零+$/, '')
        //将【零一十】换成【零十】
        //result = result.replace(/零一十/g, '零十');//貌似正规读法是零一十
        //将【一十】换成【十】
        result = result.replace(/^一十/g, '十');

		if(nums.length > 1){
            if(result.length==0 || result == '负'){
                result += "零";
            }
            
			result += "点"+nums[1].split('').map(n => arr1[n]).join("");
		}
		
        return result;
    }


    let nums = convertToChinaNum(weight).split("");
    let map = {
        '零':0, 
        '一':1, 
        '二':2, 
        '三':3, 
        '四':4, 
        '五':5, 
        '六':6, 
        '七':7, 
        '八':8, 
        '九':9,
        '点':10,
        '十':11,
        '百':12,
        '千':13,
        '万':14,
        '负':15,
    }

    let numWavs = [];
    for(let i=0; i<nums.length; i++){
        let ch = map[nums[i]];
        let wav = this.sources[ch];
        // let wav = this.wavs[ch];
        numWavs.push(wav);
    }

    // let mergedWav = this.mergeWav(numWavs);
    // return mergedWav;
    return numWavs;
}

/**
 * 获取重量的语音文件
 * @param {Double} weight 
 */
PlayWeight.prototype.getSimpleWavMedia = function(weight){
    let nums = weight.split("");
   
    let numWavs = [];
    for(let i=0; i<nums.length; i++){
        let ch = nums[i];

        let wav = -1;
        if(ch.indexOf("-")>-1){
            wav = this.sources[15];            
            // wav = this.wavs[10];            
        }if(ch.indexOf(".")>-1){
            wav = this.sources[10];            
            // wav = this.wavs[10];            
        }else{
            wav = this.sources[ch*1];
            // wav = this.wavs[ch*1];
        }
        numWavs.push(wav);
    }

    // let mergedWav = this.mergeWav(numWavs);
    // return mergedWav;
    return numWavs;
}

/**
 * 将多个wav格式的文件拼接成一个wav格式的文件。
 * @param {ArrayBuffer} arr 
 */
PlayWeight.prototype.mergeWav = function (arr) {

    //标准的wav前44个字节是wav信息
    var BufferHolder = [];  //An empty array to hold the buffers.
    var headerBuf = arr[0].slice(0,46);
    var dv = new DataView(headerBuf);
    
    var dataLen = 0;
    for (let b = 0; b < arr.length; b++) {
        //这里adobe Audition 结尾会有一个xml节点，这里需要删除
        //其byte的长度为5000， 使用的是cs6版本。
        // let rawData = arr[b].slice(80, arr[b].byteLength - 5000);
        // let rawData = new Uint8Array(arr[b], 44, arr[b].byteLength - 45)

        let start = 46;
        let len1 = 46;

        let rawData = new Uint8Array(arr[b], start, arr[b].byteLength - len1)
        BufferHolder.push(rawData);
        let len = rawData.byteLength;
        dataLen += len;
    }
    dv.setUint32(4, dataLen + 38, true); 
    dv.setUint32(42, dataLen, true); 

    let merged = [headerBuf].concat(BufferHolder);
    let blob = new Blob(merged, { type: 'audio/wav' });

    // let downloadLink = document.createElement('a');
    // downloadLink.download = "merged.wav";
    // downloadLink.href = window.webkitURL.createObjectURL(blob);
    // downloadLink.click();

    return blob;
}

export default PlayWeight;